<template>
    <div>
        <div>
            <appHhead></appHhead>
        </div>
    </div>
</template>
<script>
import appHhead from '../../components/app/header.vue'
export default {
    name:"appHome",
    data() {
        return {
            
        }
    },
    components:{
        appHhead
    }
}
</script>
<style lang="scss">
    
</style>