<template>
  <div class="app-header">
    <img src="" alt="" />
    <div>
      <i class="iconfont caidan"></i>
      <i class="iconfont guanbi"></i>
      <div>
        <span>首页</span>
        <span>产品中心</span>
        <span>新闻中心</span>
        <span>实践案例</span>
        <span>关于我们</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};                                  
</script>
<style lang="scss">
.app-header {
  height: 50px;
  background-color: rgb(55, 55, 55);
  color: #fff;
  i {
    font-size: 30px;
  }
}
</style>